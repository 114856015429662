import { getCommonParamsOptions } from '@/utils/common'
// import Validator from '@/validators'
import { isNumber } from '@/utils/validate'

export const baseFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'cardNumber',
      type: 'input',
      label: 'card.card-no'
    },
    {
      field: 'cardName',
      type: 'input',
      label: 'card.card-name',
      otherOptions: {
        maxlength: 80
      }
    },
    {
      field: 'cardType',
      type: 'select',
      label: 'common.app-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('card_type')
    }
  ],
  validateRules: {
    cardNumber: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      },
      { validator: isNumber, trigger: 'change' },
      {
        min: 13,
        max: 23,
        message: {
          locale: 'general.length-warnning-message',
          range: {
            min: 13,
            max: 23
          }
        },
        trigger: 'change'
      }
    ],
    cardName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
      // { validator: Validator.minLength, length: 3 },
      // { validator: Validator.maxLength, length: 10 }
    ],
    status: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    cardType: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
