<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/card.new.js'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import { useRouter } from 'vue-router'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const router = useRouter()

pageTitle.value = t('general.router-card-new')

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const handleSubmitClick = async (data) => {
  handleMessageCommit('param/handleAddCardAction', data).then(() => {
    router.push('/parameter/card')
  })
}
</script>
